import axios from 'axios'
import { API } from '@/API.js'
import { param } from 'jquery'

const apiClient = axios.create({
  baseURL: `${API}/incidencias/caidas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  caidasJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/incidencias/caidas.json`, { params: params })
  },

  eliminarRegistros(idRouter, params){
    params = params || {}
    return apiClient.delete(`routers/${idRouter}/eliminar-registro`, { params: params })
  },

  estabilidadRedJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/incidencias/estabilidad-red.json`, { params: params })
  }
}
