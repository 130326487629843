<template>
  <Layout :tituloPagina="`Network | Estado de la red`">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-start">
          <div class="flex-grow-1">
            <h5 class="card-title mb-4">
              Estabilidad de red
            </h5>
          </div>
          <div class="flex-shrink-0">
            <div class="dropdown">
              <a
                class="dropdown-toggle text-muted"
                href="javascript: void(0);"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{tipo_estabilidad=='todos' ? 'Todos' : ''}}
                {{tipo_estabilidad=='routers' ? 'Routers' : ''}}
                {{tipo_estabilidad=='isps' ? 'ISPs' : ''}}
                {{tipo_estabilidad=='cpes' ? 'CPEs' : ''}}
                <i class="mdi mdi-chevron-down ms-1"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a 
                  class="dropdown-item" 
                  :class="{'active': tipo_estabilidad=='todos'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_estabilidad='todos',
                    refrescarEstabilidad()
                  "
                >
                  Todos
                </a>
                <a 
                  class="dropdown-item"
                  :class="{'active': tipo_estabilidad=='routers'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_estabilidad='routers',
                    refrescarEstabilidad()
                  "
                >
                  Routers
                </a>
                <a 
                  class="dropdown-item"
                  :class="{'active': tipo_estabilidad=='isps'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_estabilidad='isps',
                    refrescarEstabilidad()
                  "
                >
                  ISPs
                </a>
                <a
                  class="dropdown-item"
                  :class="{'active': tipo_estabilidad=='cpes'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_estabilidad='cpes',
                    refrescarEstabilidad()
                  "
                >
                  CPEs
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8 text-center">
            <button
              type="button"
              class="btn bg-gradient btn-sm btn-light"
              @click="anterior()"
            >
              <i class=" mdi mdi-chevron-left"></i>
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-sm"
              :class="tipo_tiempo=='hora' ? 'btn-primary' : 'btn-light'"
              @click="
                tipo_tiempo = 'hora',
                tiempo_duracion = 1,
                refrescarEstabilidad()
              "
            >
              Hora
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-sm"
              :class="tipo_tiempo=='dia' ? 'btn-primary' : 'btn-light'"
              @click="
                tipo_tiempo = 'dia',
                tiempo_duracion = 1,
                refrescarEstabilidad()
              "
            >
              Dia
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-sm"
              :class="tipo_tiempo=='un_mes' ? 'btn-primary' : 'btn-light'"
              @click="
                tipo_tiempo = 'un_mes',
                tiempo_duracion = 1,
                refrescarEstabilidad()
              "
            >
              1 Mes
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-sm"
              :class="tipo_tiempo=='seis_meses' ? 'btn-primary' : 'btn-light'"
              @click="
                tipo_tiempo = 'seis_meses',
                tiempo_duracion = 1,
                refrescarEstabilidad()
              "
            >
              6 Meses
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-sm"
              :class="tipo_tiempo=='un_anio' ? 'btn-primary' : 'btn-light'"
              @click="
                tipo_tiempo = 'un_anio',
                tiempo_duracion = 1,
                refrescarEstabilidad()
              "
            >
              1 Año
            </button>
            <button
              type="button"
              class="btn bg-gradient btn-light btn-sm"
              @click="siguiente()"
              :disabled="tiempo_duracion <= 1"
            >
              <i class="mdi mdi-chevron-right"></i>
            </button>
          </div>
          <div class="col-lg-2 text-right text-uppercase">
            <div class="mt-1 w-100">
              {{ lapso_tiempo }}
            </div>
          </div>
        </div>
        <div id="graficaEstabilidad">
          <apexchart
            class="apex-charts"
            height="350"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="row d-flex align-items-start">
          <div class="col-lg-3 col-md-3 col-sm-4 mb-3">
            <h5 class="card-title text-truncate">
              Registro de incidencias
            </h5>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-4 col-6">
            <div class="input-group">
                <span class="input-group-text">
                  <i class="mdi mdi-map-marker-radius-outline"></i>&nbsp;Sitio
                </span>
                <select
                  class="form-select"
                  v-model="id_sitio"
                  @change="
                    id_router=null,
                    cargarRoutersXSitio(),
                    refrescarCaidas()
                  "
                >
                  <option :value="null">Todos</option>
                  <option v-for="sitio in sitios" :key="sitio.id" :value="sitio.id">
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                <i class="mdi mdi-router"></i>&nbsp;Router
              </span>
              <select
                class="form-select"
                v-model="id_router"
                @change="refrescarCaidas()"
              >
                <option :value="null">Todos</option>
                <option
                  v-for="router in routers"
                  :key="router.id"
                  :value="router.id"
                  >{{ router.nombre }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12 col-12 text-right">
            <div class="dropdown">
              <a
                class="dropdown-toggle text-muted text-truncate"
                href="javascript: void(0);"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{tipo_fecha=='hora' ? 'Ultima hora' : ''}}
                {{tipo_fecha=='dia' ? 'Dia' : ''}}
                {{tipo_fecha=='semana' ? 'Semana' : ''}}
                {{tipo_fecha=='mes' ? 'Mes' : ''}}
                {{tipo_fecha=='anio' ? 'Año' : ''}}
                {{tipo_fecha=='rango' ? 'Rango de fechas' : ''}}
                <i class="mdi mdi-chevron-down ms-1"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a 
                  class="dropdown-item" 
                  :class="{'active': tipo_fecha=='hora'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='hora',
                    refrescarCaidas()
                  "
                >
                  Ultima hora
                </a>
                <a 
                  class="dropdown-item"
                  :class="{'active': tipo_fecha=='dia'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='dia',
                    refrescarCaidas()
                  "
                >
                  Dia
                </a>
                <a
                  class="dropdown-item"
                  :class="{'active': tipo_fecha=='semana'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='semana',
                    refrescarCaidas()
                  "
                >
                  Semana
                </a>
                <a
                  class="dropdown-item"
                  :class="{'active': tipo_fecha=='mes'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='mes',
                    refrescarCaidas()
                  "
                >
                  Mes
                </a>
                <a
                  class="dropdown-item"
                  :class="{'active': tipo_fecha=='anio'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='anio',
                    refrescarCaidas()
                  "
                >
                  Año
                </a>
                <a
                  class="dropdown-item"
                  :class="{'active': tipo_fecha=='rango'}"
                  href="javascript: void(0);"
                  @click="
                    tipo_fecha='rango',
                    refrescarCaidas()
                  "
                >
                  Rango de fechas
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="tipo_fecha == 'rango'">
          <div class="col-md-6 col-sm-2"></div>
          <div class="col-md-3 col-sm-5 col-12">
            <div class="input-group mb-4">
              <span class="input-group-text">
                Inicio
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_inicial"
                @change="refrescarCaidas()"
              />
              <button
                class="btn btn-light" 
                @click="
                  fecha_inicial = '',
                  refrescarCaidas()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3 col-sm-5 col-12">
            <div class="input-group mb-4">
              <span class="input-group-text">
                Fin
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_final"
                @change="refrescarCaidas()"
              />
              <button
                class="btn btn-light"
                @click="
                  fecha_final = '',
                  refrescarCaidas()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn bg-gradient btn-sm"
            :class="tipo_caida=='todos' ? 'btn-primary' : 'btn-light'"
            @click="
              tipo_caida = 'todos',
              refrescarCaidas()
            "
          >
            Todos
          </button>
          <button
            type="button"
            class="btn bg-gradient btn-sm"
            :class="tipo_caida=='routers' ? 'btn-primary' : 'btn-light'"
            @click="
              tipo_caida = 'routers',
              refrescarCaidas()
            "
          >
            Routers
          </button>
          <button
            type="button"
            class="btn bg-gradient btn-sm"
            :class="tipo_caida=='isps' ? 'btn-primary' : 'btn-light'"
            @click="
              tipo_caida = 'isps',
              refrescarCaidas()
            "
          >
            ISPs
          </button>
          <button
            type="button"
            class="btn bg-gradient btn-sm"
            :class="tipo_caida=='cpes' ? 'btn-primary' : 'btn-light'"
            @click="
              tipo_caida = 'cpes',
              refrescarCaidas()
            "
          >
            CPEs
          </button>
        </div>

        <div class="table-responsive" style="min-height: 475px;">
          <table class="table table-hover table-nowrap" id="tblCaidas">
            <thead>
              <tr>
                <th style="width: 50px">Caida</th>
                <th style="width: 150px">Desde</th>
                <th>Hasta</th>
                <th style="width: 150px" class="text-left">Duración</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="caida in caidas">
                <tr v-if="true" :key="caida.id">
                  <td class="fw-semibold">
                    <button
                      @click="palancaInformacion(caida)"
                      class="btn btn-ling btn-xs"
                    >
                      <i
                        class="mdi"
                        :class="{
                          'mdi-chevron-down': !caida.info, 
                          'mdi-chevron-up': caida.info
                        }"
                      ></i>
                    </button>
                    <span
                      class="badge font-size-12 bg-gradient text-uppercase"
                      :class="{
                        'bg-soft-danger text-danger': caida.tipo_caida == 'Caida router',
                        'bg-soft-warning text-warning': caida.tipo_caida == 'Caida isp',
                        'bg-soft-info text-info': caida.tipo_caida == 'Caida cpe'
                      }"
                    >
                      {{ caida.tipo_caida }}
                    </span>
                  </td>
                  <td>
                    {{caida.inicio ? formatoFecha(caida.inicio, 'dddd, DD/MM/YYYY - hh:mm a') : ''}}
                  </td>
                  <td>
                    {{caida.fin ? formatoFecha(caida.fin, 'dddd, DD/MM/YYYY - hh:mm a') : 'Ahora'}}
                  </td>
                  <td class="text-left">
                    {{ caida.fin ? duracion(caida.inicio, caida.fin) : intervalos(caida.inicio, caida) }}
                    {{ !caida.fin ? caida.duracion : '' }}
                  </td>
                </tr>
                <tr v-if="caida.info" :key="`caida-info-${caida.id}`">
                  <td colspan="2">
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida router'">
                      <strong>Router:</strong>
                      <router-link
                        v-if="caida.router"
                        :to="{
                          name: 'edicionRouter',
                          params: { id: caida.id_router }
                        }"
                        class="ms-2"
                      >
                        {{ caida.router.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida cpe'">
                      <strong>CPE:</strong>
                      <router-link
                        v-if="caida.cpe"
                        :to="{
                          name: 'edicionCpe',
                          params: { id: caida.id_cpe }
                        }"
                        class="ms-2"
                      >
                        {{ caida.cpe.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida isp'">
                      <strong>ISP:</strong>
                      <router-link
                        v-if="caida.isp"
                        :to="{
                          name: 'edicionProveedorInternetRouter',
                          params: { id_isp: caida.id_isp }
                        }"
                        class="ms-2"
                      >
                        {{ caida.isp.nombre }}
                      </router-link>
                    </span>
                  </td>
                  <td colspan="3">
                    <span class="ms-4" v-if="caida.sitio">
                      <strong>Sitio:</strong>
                      <router-link
                        v-if="caida.sitio"
                        :to="{
                          name: 'edicionSitio',
                          params: { id: caida.id_sitio }
                        }"
                        class="ms-2"
                      >
                        {{ caida.sitio.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="!caida.sitio && caida.router">
                      <strong>Sitio:</strong>
                      <router-link
                        v-if="caida.router"
                        :to="{
                          name: 'edicionSitio',
                          params: { id: caida.router.id_sitio }
                        }"
                        class="ms-2"
                      >
                        {{ caida.router.sitio.nombre }}
                      </router-link>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarCaidas()
        "
      ></paginador>
    </div>
    <br>
  </Layout> 
</template>

<script>
import IncidenciaCaidasSrv from '@/services/incidencia/IncidenciaCaidasSrv.js'
import Paginador from '@/components/Paginador.vue'
import RouterSrv from '@/services/RouterSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import Layout from '@/views/layouts/main'
import moment from 'moment'
export default {
  name: 'EstadoRed',
  components: { Layout, Paginador },
  data() {
    return {
      tipo_estabilidad: 'todos', // todos, routers, isps, cpes
      tipo_tiempo: 'hora', // hora, dia, un_mes, seis_meses, un_anio

      tiempo_duracion: 1,
      lapso_tiempo: '',

      estabilidades: [],

      id_sitio: null,
      id_router: null,
      tipo_fecha: 'hora', // hora, dia, semana, mes, anio, rango
      tipo_caida: 'todos', // todos, routers, isps, cpes
      fecha_inicial: '',
      fecha_final: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.estadoRed.por_pagina') || 10),
      tc: 0, // Total de caidas
      totpag: 0, // Total de páginas

      routers: [],
      sitios: [],
      caidas: [],
      intervals: [],

      // grafica
      series: [
        {
          name: 'estabilidad',
          data: [],
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          stacked: false,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#3b76e1"],
        title: {
          text: '',
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [/* "Nov","Dic" */ ],
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      },
    }
  },
  beforeUnmount: function() {
    var self = this
    self.intervals.forEach(i => { clearInterval(i) })
  },
  mounted() {
    iu.spinner.mostrar('#tblCaidas')
    iu.spinner.mostrar('#graficaEstabilidad')
  },

  created() {
    var self = this

    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.fecha_inicial) this.fecha_inicial = this.$route.query.fecha_inicial
    if (this.$route.query.fecha_final) this.fecha_final = this.$route.query.fecha_final
    if (this.$route.query.tipo_caida) this.tipo_caida = this.$route.query.tipo_caida
    if (this.$route.query.id_sitio) this.id_sitio = parseInt(this.$route.query.id_isp)
    if (this.$route.query.id_router) this.id_router = parseInt(this.$route.query.id_router)
    if (this.$route.query.tipo_fecha) this.tipo_fecha = this.$route.query.tipo_fecha

    if (this.$route.query.tipo_estabilidad) this.tipo_estabilidad = this.$route.query.tipo_estabilidad
    if (this.$route.query.tipo_tiempo) this.tipo_tiempo = this.$route.query.tipo_tiempo
    if (this.$route.query.tiempo_duracion)
      this.tiempo_duracion = parseInt(this.$route.query.tiempo_duracion) == 0 ? 1 : parseInt(this.$route.query.tiempo_duracion)

    self.cargarSitios()
    self.cargarRoutersXSitio()
    self.refrescarCaidas()

    self.refrescarEstabilidad()
    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    window.addEventListener('resize', () => {
      self.dibujarGrafica(self.estabilidades, self.tipo_tiempo, self.tiempo_duracion)
    })
  },

  methods: {
    anterior: function() {
      var self = this
      self.tiempo_duracion++
      self.refrescarEstabilidad()
    },

    siguiente: function() {
      var self = this

      if(this.tiempo_duracion > 1) this.tiempo_duracion--
      else return
      
      self.refrescarEstabilidad()
    },
    refrescarEstabilidad: function() {
      var self = this

      iu.spinner.mostrar('#graficaEstabilidad')

      this.$router.replace({
        query: {
          page: self.page,
          epp: self.epp,
          tipo_fecha: self.tipo_fecha,
          tipo_caida: self.tipo_caida,
          fecha_inicial: self.fecha_inicial,
          fecha_final: self.fecha_final,
          id_router: self.id_router,
          id_sitio: self.id_sitio,

          // Estos campos son utilizados para la estabilidad de la red
          tipo_estabilidad: self.tipo_estabilidad,
          tipo_tiempo: self.tipo_tiempo,
          tiempo_duracion: Math.abs(self.tiempo_duracion)
        }
      }).catch(error => {})

      let params = {
        tipo_estabilidad: self.tipo_estabilidad,
        tipo_tiempo: self.tipo_tiempo
      }

      let fecha_hora_inicial = null
      let fecha_hora_final = null
      let fecha_inicial = null
      let fecha_final = null
      // hora, dia, un_mes, seis_meses, un_anio
      switch (self.tipo_tiempo) {
        case 'hora':
          fecha_hora_inicial = moment().subtract(self.tiempo_duracion, 'hours').format("YYYY-MM-DD HH:mm:ss")
          fecha_hora_final = self.tiempo_duracion == 1 ? moment().format("YYYY-MM-DD HH:mm:ss") : moment().subtract((self.tiempo_duracion-1), 'hours').format("YYYY-MM-DD HH:mm:ss")
          
          Object.assign(params, {
            fecha_hora_inicial: fecha_hora_inicial,
            fecha_hora_final: fecha_hora_final
          })

          self.lapso_tiempo = moment(fecha_hora_inicial, 'YYYY-MM-DD HH:mm:ss').format("HH:mm A") + ' - ' + moment(fecha_hora_final, 'YYYY-MM-DD HH:mm:ss').format("HH:mm A")
        break;
        case 'dia':
          fecha_inicial = moment().subtract(self.tiempo_duracion, 'days').format("YYYY-MM-DD")
          fecha_final = self.tiempo_duracion == 1 ? moment().format("YYYY-MM-DD") : moment().subtract((self.tiempo_duracion-1), 'days').format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })

          self.lapso_tiempo = moment(fecha_inicial, 'YYYY-MM-DD').locale('es-mx').format("DD/MMM") + ' - ' + moment(fecha_final, 'YYYY-MM-DD').locale('es-mx').format("DD/MMM")
        break;
        case 'un_mes':
          fecha_inicial = moment().subtract(self.tiempo_duracion, 'months').format("YYYY-MM-DD")
          fecha_final = self.tiempo_duracion == 1 ? moment().format("YYYY-MM-DD") : moment().subtract((self.tiempo_duracion-1), 'months').format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })

          self.lapso_tiempo = moment(fecha_inicial, 'YYYY-MM-DD').locale('es-mx').format("MMM") + ' - ' + moment(fecha_final, 'YYYY-MM-DD').locale('es-mx').format("MMM")
        break;
        case 'seis_meses':
          fecha_inicial = moment().subtract((self.tiempo_duracion*6), 'months').format("YYYY-MM-DD")
          fecha_final = self.tiempo_duracion == 1 ? moment().format("YYYY-MM-DD") : moment().subtract(((self.tiempo_duracion-1)*6), 'months').format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })
          
          self.lapso_tiempo = moment(fecha_inicial, 'YYYY-MM-DD').locale('es-mx').format("MMM") + ' - ' + moment(fecha_final, 'YYYY-MM-DD').locale('es-mx').format("MMM")
        break;
        case 'un_anio':
          fecha_inicial = moment().subtract(self.tiempo_duracion, 'years').format("YYYY-MM-DD")
          fecha_final = self.tiempo_duracion == 1 ? moment().format("YYYY-MM-DD") : moment().subtract((self.tiempo_duracion-1), 'years').format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })

          self.lapso_tiempo = moment(fecha_inicial, 'YYYY-MM-DD').format("YYYY") + ' - ' + moment(fecha_final, 'YYYY-MM-DD').format("YYYY")
        break;
        default:
          if(self.fecha_inicial != '' && self.fecha_final == '') {
            Object.assign(params, {
              fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
              fecha_final: moment(self.fecha_inicial).format('YYYY-MM-DD')
            })
          }

          if(self.fecha_inicial != '' && self.fecha_final != '') {
            Object.assign(params, {
              fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
              fecha_final: moment(self.fecha_final).format('YYYY-MM-DD')
            })
          }
        break;
      }
      
      IncidenciaCaidasSrv.estabilidadRedJSON(params).then(response => {
        self.estabilidades = response.data
        self.dibujarGrafica(self.estabilidades, self.tipo_tiempo, self.tiempo_duracion, fecha_hora_inicial, fecha_inicial)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#graficaEstabilidad')
      })
    },
    dibujarGrafica(porcentajes = [], tipo_tiempo = 'hora', tiempo_duracion = 1, hi = null, fi = null) {
      var self = this, categories = [], tickAmount = 20

      self.series[0].data = []

      switch (tipo_tiempo) {
        case 'hora':
          tickAmount = 20
          if(hi == null) hi = moment().subtract(tiempo_duracion, 'hours').format("HH:mm")
          else hi = moment(hi, 'YYYY-MM-DD HH:mm:ss').format("HH:mm")

          categories.push(hi)
          for(let n = 1; n<= 60; n++){
            let htmp = moment(hi, 'HH:mm').add(n, 'minute').format('HH:mm')
            categories.push(htmp)
          }

          categories.forEach(c => {
            let porcentaje = porcentajes.find(p => {
              return p.tiempo == c
            })

            if(!porcentaje) self.series[0].data.push(100)
            else self.series[0].data.push((100 - porcentaje.porcentaje).toFixed(3))
          })
        break;
        case 'dia':
          tickAmount = 20
          if(hi == null) hi = moment().subtract(tiempo_duracion, 'days').format("HH:mm")
          else hi = moment(hi, 'HH:mm:ss').format("HH:mm")

          categories.push(hi)
          for(let n = 1; n<= 144; n++){
            let htmp = moment(hi, 'HH:mm').add(n*10, 'minute').format('HH:mm')
            categories.push(htmp)
          }

          categories.forEach((c, index) => {
            let rango_pors = porcentajes.filter(p => {
              let divcat = c.split(':')
              let divp = p.tiempo.split(':')

              let divcata = index != 0 ? categories[index-1].split(':') : null

              if(divcata == null) return p.tiempo == c
              
              return divp[0] >= divcata[0] && divp[0] <= divcat[0] && divp[1] > divcata[1] && divp[1] <= divcat[1]
            })

            if(rango_pors.length > 0) {
              let pts = []
              rango_pors.forEach(r => {
                pts.push(parseFloat(r.porcentaje))
              })

              let sum = pts.reduce((previous, current) => current += previous)
              let promedio = sum / pts.length
              promedio = promedio

              self.series[0].data.push((100 - promedio).toFixed(3))
            } else self.series[0].data.push(100)
          })
        break;
        case 'un_mes':
          tickAmount = 15
          if(fi == null) fi = moment().subtract(tiempo_duracion, 'months').format("DD/MM/YYYY")
          else fi = moment(fi, 'YYYY-MM-DD').format("DD/MM/YYYY")

          let ff = moment().format("DD/MM/YYYY")

          categories.push(fi)
          let g = true
          for(let n = 1; n<= 32; n++){
            let ftmp = moment(fi, 'DD/MM/YYYY').add(n, 'days').format('DD/MM/YYYY')
            if(g) categories.push(ftmp)

            if(ff == ftmp) g = false
          }

          categories.forEach(c => {
            let porcentaje = porcentajes.find(p => {
              return p.tiempo == c
            })

            if(!porcentaje) self.series[0].data.push(100)
            else self.series[0].data.push((100 - porcentaje.porcentaje).toFixed(3))
          })
        break;
        case 'seis_meses': {
          tickAmount = 15
          if(fi == null) fi = moment().subtract((tiempo_duracion*6), 'months').format("DD/MM/YYYY")
          else fi = moment(fi, 'YYYY-MM-DD').format("DD/MM/YYYY")

          let ff = moment().format("DD/MM/YYYY")

          categories.push(fi)
          let g = true
          for(let n = 1; n<= 190; n++){
            let ftmp = moment(fi, 'DD/MM/YYYY').add(n, 'days').format('DD/MM/YYYY')
            if(g) categories.push(ftmp)

            if(ff == ftmp) g = false
          }

          categories.forEach(c => {
            let porcentaje = porcentajes.find(p => {
              return p.tiempo == c
            })

            if(!porcentaje) self.series[0].data.push(100)
            else self.series[0].data.push((100 - porcentaje.porcentaje).toFixed(3))
          })
        }
        break;
        case 'un_anio': {
          tickAmount = 15
          if(fi == null) fi = moment().subtract(tiempo_duracion, 'years').format("DD/MM/YYYY")
          else fi = moment(fi, 'YYYY-MM-DD').format("DD/MM/YYYY")

          let ff = moment().format("DD/MM/YYYY")

          categories.push(fi)
          let g = true
          for(let n = 1; n<= 366; n++){
            let ftmp = moment(fi, 'DD/MM/YYYY').add(n, 'days').format('DD/MM/YYYY')
            if(g) categories.push(ftmp)

            if(ff == ftmp) g = false
          }

          categories.forEach(c => {
            let porcentaje = porcentajes.find(p => {
              return p.tiempo == c
            })

            if(!porcentaje) self.series[0].data.push(100)
            else self.series[0].data.push((100 - porcentaje.porcentaje).toFixed(3))
          })
        }
        break;
      }

      let colors = []
      switch (self.tipo_estabilidad) {
        case 'routers':
          colors = ["#63ad6f"]
        break;
        case 'isps':
          colors = ["#f7cc53"]
        break;
        case 'cpes':
          colors = ["#57c9eb"]
        break;
        default:
          colors = ["#3b76e1"]
        break;
      }

      self.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: colors,
        title: {
          text: "Estabilidad de RED",
          align: "left",
          style: {
            fontWeight: 400,
          },
        },
        yaxis: {
          min: 0,
          max: 100
        },
        xaxis: {
          categories: categories,
          tickAmount: tickAmount,
          tooltip: {
            enabled: true,
          }
        },
      }
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarCaidas({ page: n })
    },
    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarRoutersXSitio: function() {
      var self = this
      let idSitio = self.id_sitio == null ? 0 : self.id_sitio

      RouterSrv.routerSitioJSON(idSitio).then(response => {
        self.routers = response.data
      }).catch(error => {
        if (self.id_sitio != -1){
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudieron cargar los routers'
          }
          iu.msg.error(mensaje)
        }
      })
    },
    intervalos(inicio = null, caida){
      var self = this
      self.intervals.push(
        setInterval(function(){
          let fin = moment().format("YYYY-MM-DD HH:mm:ss")
          caida.duracion = self.duracion(inicio, fin)
        }, 1000)
      )
    },
    palancaInformacion: function(caida) {
      if (caida.info) caida.info = false
      else caida.info = true
    },
    duracion(inicio = null, fin = null) {
      if(fin == null) return

      inicio = moment(inicio)
      fin = moment(fin)
      let segundos = fin.diff(inicio,"seconds")

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      var seg = Math.floor((segundos % 60) % 60)

      let cadena = ''
      if(dias > 0) cadena+= dias+' dia '
      if(horas > 0) cadena+= horas+' hrs '
      if(minutos > 0) cadena+= minutos+' min '
      if(segundos > 0) cadena+= seg+' seg'

      return cadena
    },
    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },
    refrescarCaidas: function() {
      var self = this

      iu.spinner.mostrar('#tblCaidas')

      this.$router.replace({
        query: {
          page: self.page,
          epp: self.epp,
          tipo_fecha: self.tipo_fecha,
          tipo_caida: self.tipo_caida,
          fecha_inicial: self.fecha_inicial,
          fecha_final: self.fecha_final,
          id_router: self.id_router,
          id_sitio: self.id_sitio,

          // Estos campos son utilizados para la estabilidad de la red
          tipo_estabilidad: self.tipo_estabilidad,
          tipo_tiempo: self.tipo_tiempo,
          tiempo_duracion: Math.abs(self.tiempo_duracion)
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        tipo_caida: self.tipo_caida,
        id_router: self.id_router,
        id_sitio: self.id_sitio
      }

      let hora_inicial = null
      let hora_final = null
      let fecha_inicial = null
      let fecha_final = null
      // hora, dia, semana, mes, anio, rango
      switch (self.tipo_fecha) {
        case 'hora':
          hora_inicial = moment().subtract(1, 'hours').format("HH:mm:ss")
          hora_final = moment().format("HH:mm:ss")
          
          Object.assign(params, {
            hora_inicial: hora_inicial,
            hora_final: hora_final
          })
        break;
        case 'dia':
          fecha_inicial = moment().subtract(1, 'days').format("YYYY-MM-DD")
          fecha_final = moment().format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })
        break;
        case 'semana':
          fecha_inicial = moment().subtract(1, 'weeks').format("YYYY-MM-DD")
          fecha_final = moment().format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })
        break;
        case 'mes':
          fecha_inicial = moment().subtract(1, 'months').format("YYYY-MM-DD")
          fecha_final = moment().format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })
        break;
        case 'anio':
          fecha_inicial = moment().subtract(1, 'years').format("YYYY-MM-DD")
          fecha_final = moment().format("YYYY-MM-DD")
          
          Object.assign(params, {
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final
          })
        break;
        default:
          if(self.fecha_inicial != '' && self.fecha_final == '') {
            Object.assign(params, {
              fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
              fecha_final: moment(self.fecha_inicial).format('YYYY-MM-DD')
            })
          }

          if(self.fecha_inicial != '' && self.fecha_final != '') {
            Object.assign(params, {
              fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
              fecha_final: moment(self.fecha_final).format('YYYY-MM-DD')
            })
          }
        break;
      }

      self.intervals.forEach(i => { clearInterval(i) })
      IncidenciaCaidasSrv.caidasJSON(params).then(response => {
        localStorage.setItem('argusblack.estadoRed.por_pagina', self.epp)

        let page = response.data
        self.caidas = page.data
        self.tc = page.total
        self.totpag = page.last_page

        self.caidas.forEach(c => {
          c.info = false
          c.duracion = null
          return c
        })

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarCaidas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los caidas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblCaidas')
      })
    }
  }
}
</script>

<style scoped>
</style>